import React from "react"
import { Container, Row, Col, ListGroup } from "react-bootstrap"

const Software = ({ children, pageInfo }) => {

    // let members = pageInfo.members.map((member) =>
    //     <Row key={member.key}  id={member.key}>
    //         <Col>
    //             <div className={'member'}>
    //                 <div className="contentBlock">
    //                     <div className="clickBlock">
    //                         <div className="" data-toggle="collapse" data-target={'#board-content-' + member.key} >
    //                             <a className="btn btn-link btn-sm">
    //                                 <i className="fal fa-fw fa-plus-square fa-2x d-inline mt-1 mr-2"></i>
    //                                 <i className="fal fa-fw fa-minus-square fa-2x d-none mt-1 mr-2"></i>
    //                             </a>
    //                             <h2 className="d-inline pb-1 title">
    //                                 { member.name }
    //                             </h2>
    //                             <h3 className="subtitle">
    //                                 {member.title}
    //                             </h3>
    //                         </div>
    //                     </div>
    //                     <div id={'board-content-' + member.key} className="content collapse" dangerouslySetInnerHTML={{__html: member.bio }}></div>
    //                 </div>
    //             </div>
    //         </Col>
    //     </Row>
    // )

    function skipIfBlank(input) {
        if (input) {
            return <div dangerouslySetInnerHTML={{__html: input.replace(/(?:\r\n|\r|\n)/g, '<br>')}}></div>;
        }
    }

    function Expand(license, author) {
        if (license || author) {
            return <a className="btn btn-link btn-sm">
                    <i className="fal fa-fw fa-plus-square fa-2x d-inline mt-1 mr-2"></i>
                    <i className="fal fa-fw fa-minus-square fa-2x d-none mt-1 mr-2"></i>
                   </a>
        } else {
            return '';
        }
    }

    let software = Object.keys( pageInfo.licenses ).map((key, index) =>
        <ListGroup>
            <ListGroup.Item key={key}  id={key}>
                    <div className={'package'}>

                        <div className="contentBlock">
                            <div className="clickBlock">
                                <div className="package-item" data-toggle="collapse" data-target={'#software-content-' + index} >
                                    { Expand(pageInfo.licenses[key].authors, pageInfo.licenses[key].license) }
                                    <h2 className="d-inline pb-1 title">
                                        {pageInfo.licenses[key].name} - {pageInfo.licenses[key].version}
                                    </h2>


                                </div>
                            </div>
                            <div id={'software-content-' + index} className="content collapse" >
                                <div>
                                    <div>
                                        <a href={pageInfo.licenses[key].url} target={'_blank'}>
                                            {pageInfo.licenses[key].url}
                                        </a>
                                        { skipIfBlank(pageInfo.licenses[key].authors) }
                                        <div dangerouslySetInnerHTML={{__html: pageInfo.licenses[key].licenseText.replace(/(?:\r\n|\r|\n)/g, '<br>')}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
            </ListGroup.Item>
        </ListGroup>
    )





    return (
        <section className={'software'}>
            <Container>
                <Row>
                    <Col>
                        <h2>Open Source Packages Used:</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>{children}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="softwareList">
                            {software}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )

}

export default Software
