import React from "react"
import { Row, Col, Container, ListGroup } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Jumbotron from "../components/jumbotron"
import Software from "../components/software"
import licenses from "../../static/etc/licenses.json"

const OpenSource = () => (
    <Layout pageInfo={{ pageName: "open-source" }}>
        <SEO title="Open Source Licenses" keywords={[`gatsby`, `react`, `bootstrap`]} />

        <Jumbotron pageInfo={{title: "Open Source Licenses", subtitle: "Software used in the development of our products. ", backgroundImage: '/images/resources.jpg' }} />

        <section>
            <Container>

                <Software pageInfo={{ licenses }}>
                </Software>

            </Container>
        </section>
    </Layout>
)

export default OpenSource
